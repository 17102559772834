<template>
  <v-menu left v-model="menu" :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" style="line-height: 20px; text-align: center" class="mt-2">
        <img :src="iconColumnsIcon" alt="" width="40" height="40"/>
        <div style="font-size: small">{{ $t('my_campaigns_page.columns_label') }}</div>
      </div>
    </template>
    <v-card>
      <v-list style="width: 350px" class="pb-0">
        <v-subheader>
          <h3
            style="color: rgba(0, 0, 0, 0.87)"
            class="font-family-raleway-medium"
          >
            {{ $t("my_campaigns_page.additional_text") }}
          </h3>
        </v-subheader>
        <v-divider class="pb-2" />
        <v-list-item
          class="columns-list-item"
          v-for="item of additionalColumnsData"
          :key="item.value"
        >
          <v-list-item-content>
            <v-list-item-title v-text="$t(item.label)" class="item-title" />
          </v-list-item-content>
          <v-list-item-action>
            <v-checkbox color="#0057FF" v-model="item.checked" />
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider class="mt-2" />
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>

        <v-btn text @click="menu = false"> {{ $t("cancel") }} </v-btn>
        <v-btn
          color="#0057FF"
          style="color: #ffffff; border-radius: 10px"
          class="px-4"
          @click="clickApplyButton"
        >
          {{ $t("apply_btn") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import iconColumnsIcon from "@/assets/icon/icon-columns.svg";

export default {
  name: "ColumnsComponent",
  data() {
    return {
      menu: false,
      iconColumnsIcon,
      additionalColumnsData: [
        {
          label: "my_campaigns_page.table_header_conversions",
          value: "conv",
          checked: false,
        },
        {
          label: "my_campaigns_page.table_header_cost_per_conversions",
          value: "cost_conv",
          checked: false,
        },
        {
          label: "my_campaigns_page.table_header_conversions_rate",
          value: "conv_rate",
          checked: false,
        },
        {
          label: "my_campaigns_page.table_header_conversions_value",
          value: "conv_value",
          checked: false,
        },
        {
          label: "my_campaigns_page.table_header_conversions_value_cost",
          value: "conv_value_cost",
          checked: false,
        },
      ],
    };
  },
  methods: {
    clickApplyButton() {
      this.$emit("addColumns", this.additionalColumnsData);
      this.menu = false;
    },
  },
};
</script>

<style scoped lang="scss">
.columns-list-item {
  &:hover {
    background-color: rgba(211, 211, 211, 0.5);
  }

  .item-title {
    font-size: 16px;
  }
}
</style>
